<script>
export default {
  props: {
    block: {
      type: Object,
      required: true,
    },
  },
  computed: {
    links() {
      return this.block.fields.Links;
    },
  },
};
</script>

<template>
  <div class="_linksBlock-container">
    <a class="_linksBlock-link" v-for="link in links" :key="link.Link?.id" :href="link.Link?.href">
      <span class="_linksBlock-label">{{ link.Label }}</span>
    </a>
  </div>
</template>

<style>
._linksBlock-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  padding: 0.5rem 1rem 0 1rem;
  margin: 0 auto;
  max-width: calc(400px + 0.5rem + 2 * 1rem);
}

._linksBlock-link {
  margin-bottom: 0.5rem;
  width: calc(50% - 0.5rem / 2);
  display: flex;
  align-items: center;
  padding: 12px 20px;
  background-color: #FFF;
  border: 1px solid #000;
  color: #000;
  padding: 11px 15px;
  text-align: center;
  text-decoration: none;
  font-size: 15px;
  font-family: var(--font-header);
}

._linksBlock-link:hover,
._linksBlock-link:focus {
  background-color: #000;
  color: #FFF;
}

._linksBlock-link:nth-child(2n + 1) {
  margin-right: 0.5rem;
}

._linksBlock-label {
  display: block;
  margin: 0 auto;
  text-align: center;
}
</style>
